import React from "react";
import { string, bool } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import AppetizeImage from "../../../components/ImageQuerys/AppetizeImages";
import Heading from "../../Base/HeadingBuilder";
import { viewports } from "../../../style-vars";
import background from "../../../images/svg/vector-05.svg";
import useStartMotion from "../../../hooks/use-start-motion";

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};

const slideLeftVariants = {
  hidden: { opacity: 0, x: -100, rotate: 0 },
  visible: {
    opacity: 1,
    x: 0,
    rotate: 0,
  },
};

const LargeImage = ({
  className,
  imageName,
  mobileImageName,
  title,
  animations,
}) => {
  const { ref, controls } = useStartMotion();

  return (
    <section
      className={`
      relative
      w-full
      ${className}
    `}
      ref={ref}
      data-cy="large-image"
    >
      <motion.img
        className="absolute left-0 -bottom-32 invisible md:visible"
        src={background}
        alt="background"
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={slideLeftVariants}
        transition={{ delay: 0.3, duration: 0.8 }}
      />
      <motion.div
        className=" relative mx-auto max-w-1440 w-full px-0 md:px-4 lg:px-10"
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={fadeInVariants}
        transition={{ duration: 0.8 }}
      >
        {mobileImageName && (
          <AppetizeImage
            className="rounded-large z-1 md:hidden image-mobile"
            imageName={mobileImageName}
          />
        )}

        <AppetizeImage
          className="rounded-large z-1 hidden md:block image-desktop"
          imageName={imageName}
        />

        <div
          className={`
          absolute transform -rotate-90
          text-white text-xs z-1
          bottom-28 -left-12
          md:left-0 lg:left-16
        `}
        >
          {title}
        </div>
      </motion.div>
    </section>
  );
};
LargeImage.propTypes = {
  className: string,
  imageName: string.isRequired,
  mobileImageName: string,
  title: string,
  animations: bool,
};

LargeImage.defaultProps = {
  className: "",
  mobileImageName: null,
  title: "",
  animations: true,
};

export default LargeImage;
